import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, TableSortLabel, TablePagination } from '@mui/material';

// Helper function to calculate time difference
const calculateTimeLeft = (recall_timestamp) => {
    // 2024-09-30 10:53:48.743398 what we get as recall_timestamp add +05:30 to it
    recall_timestamp = recall_timestamp + "+05:30";
    const difference = Date.parse(recall_timestamp) - Date.now() + 330 * 60 * 1000; // 330 minutes in milliseconds
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            minutes: Math.floor((difference / (1000 * 60)) % 60 + (difference / (1000 * 60 * 60)) * 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

const ReCallTable = ({ recall_data, pagination, onRowClick, onSortChange, sortOrder, onPageChange }) => {
    const [page, setPage] = useState(pagination.page - 1); // Adjust for zero-based index
    const [data, setData] = useState(recall_data); // Local state to handle data updates

    useEffect(() => {
        const timer = setInterval(() => {
            setData(prevData =>
                prevData.filter(recall_entry => {
                    const timeLeft = calculateTimeLeft(recall_entry.recall_timestamp);
                    return Object.keys(timeLeft).length !== 0; // Keep only rows where timeLeft is not empty
                })
            );
        }, 1000);

        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, []);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        pagination.page = newPage + 1; // Update to one-based index if needed
        // Assuming onPageChange updates the logs and pagination data
        onPageChange(event, newPage + 1);
    };

    const handleSortChange = (field) => {
        const isAsc = sortOrder.field === field && sortOrder.order === 'asc';
        onSortChange(field, isAsc ? 'desc' : 'asc');
    };

    const renderTimeLeft = (recall_timestamp) => {
        const timeLeft = calculateTimeLeft(recall_timestamp);

        if (Object.keys(timeLeft).length === 0) {
            return "Expired";
        }

        return `${timeLeft.minutes}m ${timeLeft.seconds}s`;
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortOrder.field === 'from_email_id'}
                                direction={sortOrder.order}
                                onClick={() => handleSortChange('from_email_id')}
                            >
                                From Email
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortOrder.field === 'subject'}
                                direction={sortOrder.order}
                                onClick={() => handleSortChange('subject')}
                            >
                                Subject
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortOrder.field === 'status'}
                                direction={sortOrder.order}
                                onClick={() => handleSortChange('status')}
                            >
                                Time Left
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((recall_entry) => (
                        <TableRow key={recall_entry.euid}>
                            <TableCell>{recall_entry.from_email_id.substring(0, 25)}</TableCell>
                            <TableCell>{recall_entry.meta_data.subject.substring(0, 25)}</TableCell>
                            <TableCell>{renderTimeLeft(recall_entry.recall_timestamp)}</TableCell>
                            <TableCell>
                                <Button variant="contained" color="primary" onClick={() => onRowClick(recall_entry)} sx={{ marginRight: 1 }}>Details</Button>
                                <Button variant="contained" color="error" onClick={() => onRowClick(recall_entry.euid)}>ReCall</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={pagination.total_count}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={pagination.per_page}
                rowsPerPageOptions={[25]}
            />
        </>
    );
};

export default ReCallTable;
